<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                                  <!-- last year history-->
                                  <b-row>
                                    <b-col sm="12">
                                      <div class="group-form-card">
                                        <b-card>
                                          <b-form-group label-cols-lg="3" :label="$t('teaGarden.fertilizer_last_year_history')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                            <!-- received histroy -->
                                            <b-col sm="12">
                                              <h6 class="mb-2">{{$t('teaGarden.fertilizer_received_history')}} {{$t('globalTrans.m_ton')}}</h6>
                                              <table class="table table-sm table-bordered section-tree-view-table">
                                                <thead>
                                                <tr>
                                                  <slot v-if="previousData.proposals && Object.keys(previousData.proposals).length > 1">
                                                    <th class="text-center" v-for="(item ,index) in previousData.proposals" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                                  </slot>
                                                  <slot v-else>
                                                    <th class="text-center" v-for="(item ,index) in formData.proposals" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                                  </slot>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                  <slot v-if="previousData.proposals && Object.keys(previousData.proposals).length > 1">
                                                    <td class="text-center" v-for="(item ,index) in previousData.proposals" :key="index">
                                                      <b-overlay :show="previousDataLoading">
                                                        {{ $n(item.receipt_qty) }}
                                                      </b-overlay>
                                                    </td>
                                                  </slot>
                                                  <slot v-else>
                                                    <td class="text-center" v-for="(item ,index) in formData.proposals" :key="index">
                                                      {{ $n(item.receipt_qty) }}
                                                    </td>
                                                  </slot>
                                                </tr>
                                                </tbody>
                                              </table>
                                            </b-col>
                                            <!-- used history -->
                                            <b-col sm="12">
                                              <h6 class="mb-2">{{$t('teaGarden.fertilizer_used_history')}} {{$t('globalTrans.m_ton')}}</h6>
                                              <table class="table table-sm table-bordered section-tree-view-table">
                                                <thead>
                                                <tr>
                                                  <th class="text-center" v-for="(item ,index) in formData.fertilizer_used_history" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                  <td class="text-center" v-for="(item ,index) in formData.fertilizer_used_history" :key="index">
                                                    {{ $n(item.used_qty) }}
                                                  </td>
                                                </tr>
                                                </tbody>
                                              </table>
                                            </b-col>
                                          </b-form-group>
                                        </b-card>
                                        <!-- Fertilizer Verification -->
                                        <b-card>
                                          <b-form-group label-cols-lg="3" :label="$t('teaGarden.fertilizer_verification')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                            <b-col sm="12">
                                              <b-table-simple small hover bordered responsive>
                                                <b-thead>
                                                  <b-tr>
                                                    <b-th>{{ $t('teaGardenConfig.fertilizer_name') }}</b-th>
                                                    <b-th>{{ $t('teaGarden.request_qty_m_ton') }}</b-th>
                                                    <b-th>{{ $t('teaGarden.verified_qty_m_ton') }}</b-th>
                                                  </b-tr>
                                                </b-thead>
                                                <b-tbody>
                                                  <b-tr v-for="(item, index) in formData.proposals" :key="'fer-' + index">
                                                    <b-td>{{ getFertilizerName(item.fertilizer_id) }}</b-td>
                                                    <b-td>{{ $n(item.reqst_qty) }}</b-td>
                                                    <b-td>
                                                      <ValidationProvider name="Verified Quantity" vid="verified_qty" :rules="'required|min_value:0|max_value:' + item.reqst_qty">
                                                        <b-form-group
                                                          class="row"
                                                          label-cols-sm="12"
                                                          label-for="verified_qty"
                                                          slot-scope="{ valid, errors }"
                                                        >
                                                          <b-form-input
                                                            id="reason"
                                                            v-model="item.verified_qty"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            @keypress="isNumber"
                                                            @input="setVerifiedQty(index)"
                                                            min="0"
                                                          ></b-form-input>
                                                          <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                          </div>
                                                        </b-form-group>
                                                      </ValidationProvider>
                                                    </b-td>
                                                  </b-tr>
                                                </b-tbody>
                                              </b-table-simple>
                                            </b-col>
                                          </b-form-group>
                                        </b-card>
                                      </div>
                                    </b-col>
                                  </b-row>
                                    <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.verify') }}</b-button>
                                        &nbsp;
                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                                    </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { fertilizerIndentVerify } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'

export default {
    name: 'FormLayout',
    props: ['id'],
    created () {
      // for live 134
      // this.officeList = this.getParentOfficeList(19)
      // for local
      this.formData = this.getFormData()
      this.officeList = this.getParentOfficeList()
      this.getPreviousRequestHistory()
    },
    watch: {
      'forward.office_id': function (newVal, oldVal) {
        this.getDesignationList()
      },
      'forward.designation_id': function (newVal, oldVal) {
        this.getUserList(newVal)
      },
      'forward.receiver_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal > 0) {
            const userList = this.userList.find(item => item.value === newVal)
            this.forward.receiver_name = userList.text_en
            this.forward.receiver_name_bn = userList.text_bn
          }
        }
      },
      currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.localizeUserList(newVal)
        }
      }
    },
    computed: {
        loading () {
          return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    data () {
        return {
            loader: false,
            formData: {},
            previousData: {
              proposals: [{}],
              fertilizer_used_history: [{}]
            },
            previousDataLoading: false
        }
    },
    methods: {
      getFormData () {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify(tmpData))
      },
      async register () {
          this.loader = true
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadinState = { loading: false, listReload: true }
          result = await RestApi.putData(teaGardenServiceBaseUrl, `${fertilizerIndentVerify}/${this.id}`, this.formData)
          this.$store.dispatch('mutateCommonProperties', loadinState)
          if (result.success) {
               this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
              this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
              })
              this.$bvModal.hide('modal-6')
          } else {
              this.$refs.form.setErrors(result.errors)
          }
          this.loader = false
      },
      isNumber (evt) {
        helpers.isNumber(evt)
      },
      getFertilizerName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(item => item.value === id)
        if (obj) {
          return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
        }
      },
      async getPreviousRequestHistory () {
        const paramsData = {
          garden_id: this.formData.garden_id,
          id: this.id,
          fiscal_year_id: this.formData.fiscal_year_id
        }
        this.previousDataLoading = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/fertilizer-request/get-last-year-firtilizer-history', paramsData)
        if (result.success) {
          const data = result.data
          if (data) {
            this.previousData = data
          }
        } else {
          this.previousData = {
            proposals: [],
            fertilizer_used_history: []
          }
        }
        this.previousDataLoading = false
      },
      setVerifiedQty (index) {
        const item = this.formData.proposals[index]
        if (item.verified_qty > item.reqst_qty) {
          this.$nextTick(() => {
            item.verified_qty = item.reqst_qty
          })
        }
      }
    }
}
</script>
